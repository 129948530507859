let memberInfo = {
    execList: [
        {
            name: 'Mrigank Shekhar',
            title: 'President',
            bio: [
                "Hey! I'm Mrigank, a junior studying business and computer science. I joined NIB my freshman spring and it's been a hell of a time since. I've had the wonderful opportunity to work on four amazing projects: two with non-profits in the education and healthcare space, a third dealing with corporate nonprofit board matching, and most recently a startup in the biotech industry. I'm particularly passionate about education and making it high quality and accessible everywhere. It's incredible how much I've learned from my projects and fellow NIB members during my time here.", 
                "Outside of enjoying my spotlight in the NIB hall of fame (jokes, but we should make it happen) I enjoy dance and watching standup. I'm beyond excited to meet y'all throughout recruitment. Cheers :)"
            ],
            linkedin: 'https://www.linkedin.com/in/shekharmrigank',
            image: require('../img/prof/mrigank.jpg'),
            sillyImage: require('../img/funny/mrigank.png'),
            isDM: true
        },
        {
            name: 'Nikith Vangala',
            title: 'VP Finance & Operations',
            bio: [
                "Hi! My name is Nikith Vangala and I'm currently a sophomore studying EECS. I joined NIB last fall and am so excited to be VP F&O this semester! NIB has been the absolute best community for me thus far, and I'm super thankful I had NIB last year while I was acclimating to college life.",
                "In NIB, I've worked on increasing transparency for health product procurement in Africa as well as helping a social enterprise better communicate its positive environmental impact. Outside of NIB, I'm a member of UCB Zahanat, a Bollywood fusion dance team."
            ],
            linkedin: 'https://linkedin.com/in/nikith-vangala',
            calendly: "https://calendly.com/nikith-vangala/nib-coffee-chats",
            image: require('../img/prof/nikith.jpg'),
            sillyImage: require('../img/funny/nikith.png'),
            isDM: true
        },
        {
            name: 'Aaliyah Pondor',
            title: 'VP Consulting',
            bio: [
                "Hey everyone! I’m Aaliyah, a Junior from Mauritius studying Business Administration and Environmental Economics and Policy. I joined NIB my spring freshmen year and it’s been the best decision I’ve made so far! From the tight-knit community to the inspiring projects I’ve been on, NIB has shaped my college experience so far! I’ve worked on an ESG project with a tech company and devising a financial model for renewable energy land conversion.",
                "In my free time I enjoy writing music, playing guitar and keeping active!"
            ],
            linkedin: 'https://www.linkedin.com/in/aaliyahpondor/',
            image: require('../img/prof/aaliyah.jpg'),
            sillyImage: require('../img/funny/aaliyah.png'),
            isDM: true
        },
        {
            name: 'Edward Tak', 
            title: 'VP External Affairs',
            bio: [
                "Hi! I’m Edward, a junior from the Bay Area studying Economics and Political Science. I joined Net Impact during the spring of my freshman year and am currently the VP of External Affairs. Previously, I worked with Parks Project on habitat restoration strategy and Best Buy on education technology access.",
                "Professionally, I will be spending the upcoming summer working as a Program Management Intern at Microsoft. After graduation, I hope to pursue a career in consulting or tech. My interests include following sports (NBA, MLB, NFL, EPL, Tennis), watching K-dramas, and grinding to Gold in League. If you see me around, please tell me a good joke lol"
            ],
            linkedin: 'https://www.linkedin.com/in/edwardtak012/',
            image: require('../img/prof/eddie.jpg'),
            sillyImage: require('../img/funny/eddie.png'),
            isDM: true
        },
        {
            name: 'Vincent Nguyen',
            title: 'VP Associate Development',
            bio: [
                "Hello! My name is Vincent. I am from Orange County and am a junior studying Psychology & Theater. NIB has been such a great opportunity for me to grow professionally by providing opportunities to work with a national non-profit healthcare organization, a biotech startup, and a global food non-profit aiming to alleviate world hunger. What makes the club special to me is that I get to see my work have a tangible impact on the world. Additionally, the community is like no other. I still keep in contact with our alumni, and I've met many amazing individuals in this club who have supported me through my highs, lows, and everything in between.",
                "I'm a first-gen college student, dancer, and play table tennis, tennis, and pickleball. If you have an interest in any of these, let's chat!"
            ],
            linkedin: 'https://www.linkedin.com/in/vincentnguyen25/',
            image: require('../img/prof/vincent.jpg'),
            sillyImage: require('../img/funny/vincent.png'),
            isDM: true
        },
        
    ],
    pmList: [
        {
            name: 'Breanna Pearlman', 
            title: 'Project Manager',
            bio: [
                "Hi! I’m Breanna, and I’m a sophomore studying Global Studies and Society & Environment with a minor in History. I joined NIB my freshman spring, and it has been the best decision I’ve made! In my short time in NIB so far I have already made such amazing memories and met some of my closest friends. Within NIB, I’ve worked on social impact initiative ideation for a leading energy bar company and food safety testing implementation for the Global Alliance for Improved Nutrition.",
                "Outside of consulting, I am interested in human rights, environmental justice, African development, and Timothée Chalamet. I am also involved in on-campus research. In my free time, I love shopping, hiking, and traveling. Feel free to reach out and chat, and I look forward to meeting you!"
            ],
            linkedin: 'https://www.linkedin.com/in/breanna-pearlman-637119251/',
            image: require('../img/prof/breanna.jpg'),
            sillyImage: require('../img/funny/breanna.png'),
            isDM: true
        },
        {
            name: 'Charlie Gu', 
            title: 'Project Manager',
            bio: [
                "Hey! My name's Charlie, and I'm a second-year majoring in Business + Electrical Engineering & Computer Science. I joined NIB during my freshman fall semester, and I've loved every moment of it. Nowhere else will you find the opportunity to work on such unique projects, gain in-person presentation experience, and meet talented and driven people. NIB is the most supportive group of people I've ever met <3",
                "In my free time, I like working out, reading, and trying new dishes. I'm always trying to become a better cook (on a budget), so if you have new recipes, hit me up. Sign up for a coffee chat with me if you are interested in video games, the Huberman Lab podcast, food, Suits (the show), or just want to learn more about NIB!"
            ],
            linkedin: 'https://www.linkedin.com/in/charleszgu/',
            image: require('../img/prof/charlie.jpg'),
            sillyImage: require('../img/funny/charlie.png'),
            isDM: true
        },
        {
            name: 'Elan Trager', 
            title: 'Project Manager',
            bio: [
                "Hello! I'm Elan, a sophomore studying Environmental Econ and Data Science. Going into my third semester at NIB, my time here has been ridiculously good. I've gotten to work with recognizable companies on relevant sustainability efforts while increasingly becoming a part of the bomb community that is NIB. This semester I’ve had the opportunity to become a project manager, and I cannot wait to dive into the projects we are developing!",
                "In my free time I can be found at the climbing gym(shoutout Mosaic), RSF, or laying on the glade in the sun with some still woozy playing."
            ],
            linkedin: 'https://www.linkedin.com/in/elan-trager-a9a2311a9/',
            image: require('../img/prof/elan.png'),
            sillyImage: require('../img/funny/elan.png'),
            isDM: true
        },
        {
            name: 'Madelyn Christensen',
            title: 'Project Manager',
            bio: [
                "Hi everyone! My name is Madelyn, and I am currently studying cognitive science and computer science. Being a part of Net Impact has been such an enriching experience, both through the skills I have acquired from workshops and team projects, as well as just getting the chance to be a part of such a fun and kind group of people. Most of my projects in NIB have focused on the healthcare industry, which is an impact area I’ve always been passionate about. During my time in NIB, I have had the opportunity to work on a project that focused on vaccine and pharmaceutical procurement within trading blocs in Africa. I've also branched out further in the healthcare industry and worked for a med tech company on bringing sustainability into the medical technology space, as well as a project focused around understanding the feasibility in increasing access to food safety tests to regions around the world. Through these projects, NIB has helped me improve the quality of my research, writing, and public speaking skills.",
                "In my free time I enjoy reading, board games, hiking, grabbing boba with friends, listening to practically every genre of music that exists, and playing tennis."
            ],
            linkedin: 'https://www.linkedin.com/in/madelynchristensen/',
            image: require('../img/prof/madelyn.jpeg'),
            sillyImage: require('../img/funny/maddy.png'),
            isDM: true
        },
        
        {
            name: 'Viviana Mercado', 
            title: 'Project Manager',
            bio: [
                "Hi! I'm Viviana and I'm a junior from Long Beach, studying MCB. I joined NIB spring of my sophomore year and was able to work with Stryker on my first project where we conducted market research and optimized CSR practices in the med-tech industry. I am pre med and interested in the intersection of medicine and business so feel free to ask me about how NIB has helped me explore this niche!! Outside of NIB I love books, podcasts, fashion, anything neuroscience related, and yoga + running."
            ],
            linkedin: 'https://www.linkedin.com/in/vivianamerc',
            calendly: "https://calendly.com/vivianamercado/viviana-mercado-calendly",
            image: require('../img/prof/viviana.png'),
            sillyImage: require('../img/funny/viviana.png'),
            isDM: true
        },
       
       
        
        
        
    ],
    memberList: [
        {
                name: 'Grace Hu', 
                title: 'Senior Associate',
                bio: [
                    "Hey! I’m Grace, a second year from Shanghai studying Political Economy and Economics with minors in Public Policy and Data Science. I joined NIB my sophomore fall and this is the best decision I made in my college experience. I’ve had the wonderful opportunity to work with a multinational corporation to enhance its food sustainability program in the past semester. NIB will always amaze you with how nice and supportive the #NIBFAM could be, and I have gained so many invaluable memories and experienced so much growth in my time with NIB thus far.",
                    "As a first-generation college student, I’m happy to talk about my journey if you are one too. In my free time, I enjoy playing piano (tho I hated it until I no longer HAVE TO), watching musicals, having random debates and discussions on philosophical topics, and giving 100% for the classes I like (and sleeping through the rest)"
                ],
                linkedin: 'https://www.linkedin.com/in/gracexyhu/',
                calendly: "https://calendly.com/grace_hu/nib-coffee-chat-sp24",
                image: require('../img/prof/grace.jpg'),
                sillyImage: require('../img/funny/grace.jpg'),
                isDM: false
        },
        {
            name: 'Hunter Valencia', 
            title: 'Senior Associate',
            bio: [
                "Hi everyone! My name is Hunter, I\'m a rising Sophomore studying Political Science. I joined Net Impact my Freshman Fall, and it\'s been an incredible year of working with the team. I especially loved how Net Impact integrated my major in Poli Sci with fields like healthcare and business.",
                "This club has created so many core memories that have shaped who I am. If you have any questions (or want to talk about Volleyball), I'd love to hear from you!"
            ],
            linkedin: 'https://www.linkedin.com/in/hunter-valencia-42216424b',
            calendly: "https://calendly.com/sunwardloki/nib-coffee-chat",
            image: require('../img/prof/hunter.jpg'),
            sillyImage: require('../img/funny/hunter.png'),
            isDM: false
        },
        {
            name: 'Srijani Sarkar', 
            title: 'Senior Associate',
            bio: [
                "Hi! I'm Srijani, a current sophomore studying Cognitive Science and MEB. I joined the NIB family last fall and will be a Senior Associate this upcoming semester. I am interested in ESG and CSR initiatives in business and had the opportunity to work with a TurtleTree, an innovator in the Biotech space, for my most recent project. Outside of NIB, I am a member of PhiDE, a pre medical fraternity. I also love to lift, paint, and play basketball and lacrosse. Feel free to sign up for a coffee chat with me, I'd love to get to know you!"
            ],
            linkedin: 'https://www.linkedin.com/in/srijani-sarkar-035016200/',
            calendly: "https://calendly.com/srijanisarkar/nib-coffee-chats",
            image: require('../img/prof/srijani.jpg'),
            sillyImage: require('../img/funny/srijani.jpeg'),
            isDM: false
    },
    {
        name: 'Ice Kulruchakorn', 
        title: 'Associate',
        bio: [
            "Hey! My name is Ice and I'm a freshman from Bangkok, Thailand, studying Political Science and Data Science. I joined NIB in my freshman fall semester and NIB has been a huge reason why I felt right at home in Berkeley. Last semester, I worked with a crowdfunding microfinance nonprofit that provides financial access to underprivileged groups globally! To me, NIB is so much more than a pre-professional club and you'll find the most talented, driven, and lovable people here. :-)",
            "Outside of NIB, I'm interested in development policy, education reforms, and politics in Southeast Asia. I also *try* to work out and run in my free time. I also love books and movies that make me cry, and I am a struggling alto trying to sing better music. Feel free to reach out and I can't wait to meet you!"
        ],
        linkedin: 'https://www.linkedin.com/in/napim-ice-kulruchakorn-6a77531b3/',
        calendly: "https://calendly.com/napimk/nib-coffee-chat",
        image: require('../img/prof/ice.jpg'),
        sillyImage: require('../img/funny/ice.jpg'),
        isDM: false
    },
    {
        name: 'Jasmine Mah', 
        title: 'Associate',
        bio: [
            "Hello!! My name is Jasmine and I am a sophomore from San Francisco studying Sociology. I joined NIB last semester (fall 23) and so far it has been such an amazing experience. This past semester, I was on a project with a microfinancing organization called Kiva. In this project we did real work with tangible results, and it was extremely gratifying and educational. Our community is so welcoming and diverse, but most importantly, so supportive. This club never fails to uplift one another and despite the different majors and interests, we all share the passion of social impact and building connections.",
            "Outside of consulting, I am very passionate about women's health issues including menstrual equity, maternal and child health, and representation in women's health. In my free time, you can find me at the gym, playing volleyball, or at the beach!! I'd be more than happy to talk about NIB, the new member experience, or even balancing NIB with other commitments. Feel free to reach out! "
        ],
        linkedin: 'https://www.linkedin.com/in/jasmine-mah-a23434235/',
        calendly: "https://calendly.com/jasminemah/nib-coffee-chatss",
        image: require('../img/prof/jasmine.jpg'),
        sillyImage: require('../img/funny/jasmine.jpg'),
        isDM: false
    },
    {
        name: 'Malik Mbugua', 
        title: 'Associate',
        bio: [
            "What’s the word y’all my name is Malik Mbugua, and I’m a second-year majoring in Political Economy with a concentration in Technology/Data Science.I joined NIB during my sophomore fall semester from South Central Los Angeles, and I’m currently a Returning Associate this semester. So far, I’ve been able to work with TurtleTree in aiding in the launch of new biomedical innovations.",
            "Aside from this, I have a love for social impact, specifically dealing with homelessness and youth outreach which I do with non-profits I’ve helped as a co-founder in Berkley and LA. If you want to talk about social impact, anime, basketball, music, or anything else random, feel free to tap in!"
        ],
        linkedin: 'https://www.linkedin.com/in/malik-mbugua?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
        calendly: "https://calendly.com/mmbugua-1/nib-coffee-chat",
        image: require('../img/prof/malik.jpg'),
        sillyImage: require('../img/funny/malik.jpg'),
        isDM: false
    },
    {
        name: 'Mariah Branscomb', 
        title: 'Associate',
        bio: [
            "Hi! I'm Mariah, a first year intending to major in Business Administration. I joined NIB my freshman fall semester and will be a returning associate this fall.  On my past project team, we guided a regenerative non-profit down the best strategy to launch their new educational product!",
            "Beyond NIB, I am interested in DEI initiatives, specifically increasing representation of women of color in professional spaces. Currently, I intern in Cal Athletics in the communications branch and worked as a sports journalist for underrepresented Bay Area athletes. In my free time I love learning new hairstyles, EATING, going to the gym, shopping, and watching movies. Feel free to reach out! "
        ],
        linkedin: 'https://www.linkedin.com/in/mariah-branscomb',
        calendly: "https://calendly.com/mariahbranscomb/nib-coffee-chat",
        image: require('../img/prof/mariah.jpg'),
        sillyImage: require('../img/funny/mariah.jpg'),
        isDM: false
    },
    {
        name: 'Muhammed Ibrahim Bakr', 
        title: 'Associate',
        bio: [
            "Hey, I'm Muhammed, a sophomore born and raised in Gaborone, Botswana (the country directly above South Africa) majoring in Economics, and exploring at minors in Data Science and Global Poverty and Practice. I joined NIB last fall and have loved every second of it! I know it sounds cliche, but there's no better way to describe the club than a family, and I can feel that even after just one semester here.",
            "I'm very interested in extreme poverty alleviation, and NIB helped me channel that passion through our work with the Global Alliance for Improved Nutrition (GAIN), where we assessed food safety testing technologies and provided possible implementation strategies for the most optimal ones in East Africa and Asia.",
            "Talk to me about anything football (soccer) related (I'm a massive Liverpool fan), the Golden State Warriors, Effective Altruism, Dominic Fike, or about being an international student confused by America. Feel free to message me with any questions you have about NIB!"
        ],
        linkedin: 'https://www.linkedin.com/in/muhammed-bakr/',
        calendly: "https://calendly.com/muhammed-bakr/30min",
        image: require('../img/prof/muhammed.jpg'),
        sillyImage: require('../img/funny/muhammed.jpg'),
        isDM: false
    },
    {
        name: 'Aditya Pattani',
        title: 'Senior Advisor',
        bio: [
            "Hey everyone! I'm Aditya and I am a senior (whoa it's been 3 years?!?  yikesyikes) majoring in Economics and Data Science. I joined Net Impact in the spring semester of my sophomore year and will be returning this semester as a Senior Advisor. I have previously worked in the fields of wealth management, startups, and consulting.",
            "Being an international student from New Delhi, the transition to Cal was not easy, especially with remote instruction during the pandemic. Net Impact not only helped me find my footing professionally but also introduced me to some of the smartest and kindest people I know.",
            "Aside from school, I love going on night drives, sleeping in, cooking, and travelling! I'd also love to chat with you about why Messi is the GOAT, or boba is better than coffee. If any of this sounds interesting to you feel free to reach out!"
        ],
        linkedin: 'https://www.linkedin.com/in/aditya-pattani-719b51205/',
        calendly: 'https://calendly.com/adityapattani',
        image: require('../img/prof/adi.jpg'),
        sillyImage: require('../img/funny/adi.png'),
        isDM: false
    },
    {
        name: 'Alexis Wright', 
        title: 'Senior Advisor',
        bio: [
           "Hey! I'm Alexis, a junior majoring in Political Economy and Global Studies, and I'll be a senior associate in NIB this semester. I joined NIB last fall, and I've had the opportunity to work on two amazing projects, first building an aerospace corporate social responsibility strategy and then working on supporting nutrition for cancer patients and caregivers. NIB is truly such a tight-knit community, and I'm so lucky to have made some of my closest friendships here.",
           "In my free time, I love thrifting and watching K-Dramas! I'm also currently in a reading slump, so if you have any book recs or if you'd like to chat about what it's like to join NIB from a non-business background, or anything at all, feel free to schedule a coffee chat! Super excited to meet you :)"
        ],
        linkedin: 'https://www.linkedin.com/in/alexisrwright/',
        calendly: "https://calendly.com/alexisrwright",
        image: require('../img/prof/alexis.jpg'),
        sillyImage: require('../img/funny/alexis.png'),
        isDM: false
    },
    {
        name: 'Heather Yuan', 
        title: 'Senior Advisor',
        bio: [
            "Hello! My name is Heather Yuan, and I'm a senior from Seattle studying Computer Science. I joined NIB in Fall 2021 and have since had the honor of leading a project for Clif Bar to fundraise for women's advocacy nonprofits through a female-directed traveling film festival. In past projects, I've worked on ESG strategy for a sustainable apparel company, go-to-market strategy for an international girl's education nonprofit, and financial model for a renewable energy company.",
            "Professionally, I'm interested in software engineering or product management, but growing up as a dancer, I still hope to reconnect to the entertainment industry somehow. In my free time, you'll probably find me dancing, at the gym, or making a mess in the kitchen. I'm excited to meet you!"
        ],
        linkedin: 'https://www.linkedin.com/in/heatheryuan/',
        calendly: "https://calendly.com/heather-yuan/nib-coffee-chat",
        image: require('../img/prof/heather.jpg'),
        sillyImage: require('../img/funny/heather.png'),
        isDM: false
    },
    
    {
        name: 'Jana Zaki', // Fa 23 Form needs to be submitted
        title: 'Senior Advisor',
        bio: [
            "Hey! I'm Jana, a junior from Cairo, Egypt studying Business Adminstration and Economics. I joined Net Impact fall of my sophomore year and will be a Senior Advisor this semester.  My previous past projects include working with GAIN, Stryker and Pattern Energy. After graduation, I hope to pursue a career in Real Estate/ Finance. My interests include travelling, playing soccer, and creating art. Always happy to chat about NIB or anything else!",
        ],
        linkedin: 'https://www.linkedin.com/in/jana-zaki-68bb65202/',
        calendly: "https://calendly.com/janazaki/coffee-chat",
        image: require('../img/prof/jana.jpg'),
        sillyImage: require('../img/funny/jana.png'),
        isDM: false
    },
    // {
    //     name: 'Jessica Yang', 
    //     title: 'Senior Advisor',
    //     bio: [
    //         "Hi! I'm Jess, a senior from Colorado studying Business. I joined Net Impact fall of my sophomore year and am a senior advisor this semester. In the past, I've worked on projects relating to sustainable fashion, clean energy, and equitable access to technology for youth.",
    //         "I'm interested in the intersection of environmental conservation and finance. This past summer I gained experience in investment banking and plan on pursuing it as a career in the future. In my free time, I love to be outdoors whether thats backpacking, hammocking, or skiing. Feel free to reach out to chat about anything!"
    //     ],
    //     linkedin: 'https://www.linkedin.com/in/jessica-yang-jy/',
    //     calendly: "https://calendly.com/jessicayang9/nib-coffee-chats",
    //     image: require('../img/prof/jess.png'),
    //     sillyImage: require('../img/funny/jess.png'),
    //     isDM: false
    // },
    {
        name: 'Justin Byers',
        title: 'Senior Advisor',
        bio: [
            "Hey everyone! My name is Justin, and I’m a senior studying Mechanical Engineering and Business Administration. I joined NIB during my fall semester of sophomore year, and it was one of the best decisions of my college experience! In the past, I’ve worked on developing ESG strategies for an international food corporation, creating a product impact dashboard for a large tech company, and leading a project to design a nutritional assistance program for oncology patients.",
            "This past summer, I interned at Arup, a sustainable development focused engineering firm. If you’re interested in the built environment, robotics, community development, or just want to talk about the latest books you’ve read, come chat with me at any of our recruitment events! Super excited to meet you all!"
        ],
        linkedin: 'https://www.linkedin.com/in/justinabyers/',
        calendly: "https://calendly.com/justin-byers-1/nib-coffee-chat",
        image: require('../img/prof/justin.jpg'),
        sillyImage: require('../img/funny/justin.png'),
        isDM: false
    },
    {
        name: 'Kavi Dolasia', 
        title: 'Senior Advisor',
        bio: [
            "Hi! I’m Kavi and I’m a junior studying Business and Economics. I joined Net Impact Berkeley during my Freshman Spring and it has been the best decision I’ve made in college!",
            "In the past, I have worked with Salesforce on impact management and evaluation, as well as a Canadian apparel company on pre-IPO ESG strategies. This past semester, I led a project with the Bill & Melinda Gates Foundation focused on global health product procurement in Sub-Saharan Africa. I will be pursuing a career in finance in the future.",
            "Please feel free to message me for any questions about Net Impact Berkeley or the recruitment process!"
        ],
        linkedin: 'https://www.linkedin.com/in/kavidolasia/',
        calendly: "https://calendly.com/kdolasia",
        image: require('../img/prof/kavi.jpg'),
        sillyImage: require('../img/funny/kavi.png'),
        isDM: false
    },
    {
        name: 'Keya Pardasani', 
        title: 'Senior Advisor',
        bio: [
            "Hi everyone! I'm Keya, a junior from the Bay Area studying Environmental Economics and Policy. I joined NIB my second semester sophomore year, and it's been the best decision of my time in college! This past semester, I worked on a project with Heifer International, and I'm so excited for future projects and opportunities.",
            "Outside of NIB, I love thrifting, hiking, and yoga! I'm also always down to talk about some good books or movies. And also, feel free to message me about NIB!",
            "Keya is studying abroad Spring 2024."
        ],
        linkedin: 'https://www.linkedin.com/in/keya-pardasani-aaa8981b1/',
        image: require('../img/prof/keya.png'),
        sillyImage: require('../img/funny/keya.png'),
        isDM: false
    },
    {
        name: 'Marcus Aina',
        title: 'Senior Advisor',
        bio: [
            "What's up! I'm Marcus Aina and I'm a third year studying Political Economy. I joined NIB during my sophomore fall and it has been a blessed experience. From consulting with an EdTech and the Leukemia Lymphoma Society to getting connected with alumni and securing an ACI role at Bain, NIB continues to be one of the greatest communities I've found for which I will always be thankful.",  
            "More about me, I'm from the Inland Empire (yuhh the IE). I spend a lot of my time experimenting with music, watching sports (NBA & UFC mainly), playing sports (basketball & boxing), and cooking new things. I'm looking to pursue a career in music/sports business, so talk to me if any of that interests you!"
        ],
        linkedin: 'https://www.linkedin.com/in/marcusaina/',
        calendly: "https://calendly.com/marcus-aina/nib-coffee-chats",
        image: require('../img/prof/marcus.png'),
        sillyImage: require('../img/funny/marcus.png'),
        isDM: false
    },
    {
        name: 'Matthew Kang',
        title: 'Senior Advisor',
        bio: [ 
            "Hi everyone I’m Matthew, and I'm a senior studying Mechanical Engineering and Business Administration. I joined NIB my freshman spring so NIB has been my first family on campus. In my four years at Cal, I have never seen an organization as warm as NIB's.",
            "I'm passionate about education accessibility + mental health awareness working in both spaces previous to coming to Berkeley. I have also continued to pursue both passions having led a NIB project for a leading EdTech start-up and speaking as a panelist for the Y4Y Summit organized by the creators of The Social Dilemma documentary.", 
            "Outside of NIB I compete in obstacle races, served as the VP of A&M for the M.E.T. program, and got out of my comfort zone last year by participating in AFX dance.", 
            "If you ever want to learn more about my NIB experience, speak about indie artists, bball, stand-up comedy or just want to talk, please feel free to reach out to me at mattkangg@berkeley.edu"
        ],
        linkedin: 'https://www.linkedin.com/in/mattkangg/',
        calendly: "https://calendly.com/mattkangg/30min",
        image: require('../img/prof/matt.jpg'),
        sillyImage: require('../img/funny/matt.png'),
        isDM: false
    },
    {
        name: 'Megha Jain',
        title: 'Senior Advisor',
        bio: [
            "Hey! I'm Megha, a senior from Los Angeles studying Computer Science + Business. I joined NIB my first semester of college and have cherished every moment since 💙 In my free time, I read/write about climate tech, build fun things, and meticulously rank Bay Area restaurants in my master spreadsheet. Feel free to sign up for a coffee chat!"
        ],
        linkedin: 'https://www.linkedin.com/in/meghamjain/',
        calendly: "https://calendly.com/meghaja1n/nib-coffee-chats",
        image: require('../img/prof/megha.png'),
        sillyImage: require('../img/funny/megha.png'),
        isDM: false
    },
    {
        name: 'Mia Chou',
        title: 'Senior Advisor',
        bio: [
            "Hi there! I'm Mia, a junior from Santa Barbara studying Industrial Engineering and Business and will be a senior advisor this upcoming semester. I joined Net Impact fall of my freshman year and it’s one of the best decisions I’ve made. Throughout my time in NIB I've had the opportunity to work on amazing projects such as building a go-to-market strategy for girl's education curriculum and research on corporate training needs for a regenerative agriculture nonprofit. My projects have allowed me to learn, grow, and most importantly meet some of my closest friends! (and housemate) NIB has truly become my family.",
            "Outside of school and NIB, some of my favorite things include trying new recipes and restaurants, running half marathons and playing tennis, listening to podcasts and audiobooks, and being overly excited about picnics and sunsets. I’m excited to meet you all!"
        ],
        linkedin: 'https://www.linkedin.com/in/mia-chou-2454a4187/',
        calendly: 'https://calendly.com/miachou/30min?back=1&month=2024-01',
        image: require('../img/prof/mia.jpg'),
        sillyImage: require('../img/funny/mia.png'),
        isDM: false
    },
    {
        name: 'Mehul Nair', 
        title: 'Senior Advisor',
        bio: [
            "Hey everyone! I'm Mehul and I'm a sophomore currently studying Nuclear Engineering and Materials Science. I joined NIB last semester and I've had a blast! I'm very passionate about climate sustainability and worked on a regenerative agriculture project last semester. My favorite area to work in is product and interface design and strategies",
            "Outside of NIB I like to try new food, go on late-night drives with friends and watch soccer. I'm also involved in multiple research projects from high-powered magnetics to algorithms for cancer therapy! If you want to know more about my experience at NIB or just chat, feel free to contact me at mehulnair@berkeley.edu."
        ],
        linkedin: 'http://linkedin.com/in/mehnai/',
        calendly: "https://calendly.com/mehulnair",
        image: require('../img/prof/mehul.jpg'),
        sillyImage: require('../img/funny/mehul.jpg'),
        isDM: false
},
{
    name: 'Nicole Chiang',
    title: 'Senior Advisor',
    bio: [
        "Hi! I'm Nicole and I’m a senior studying Molecular Cell Biology and Business Administration. I joined Net Impact my freshman Spring semester and have felt so lucky to have this incredible community supporting me. My past projects include Stryker, SoCal Edison, a large gaming company, and an EdTech startup. If you're curious about any of those experience or just want to chat about Taylor Swift, open-world games, or anything else, check out my Calendly for a coffee chat :)"
    ],
    linkedin: 'https://www.linkedin.com/in/nicole-chiang-/',
    calendly: "https://calendly.com/nicole-chiang/nib-coffee-chat-with-nicole",
    image: require('../img/prof/nicole.png'),
    sillyImage: require('../img/funny/nicole.png'),
    isDM: false
},
{
    name: 'Ojasvi Saxena', 
    title: 'Senior Advisor',
    bio: [
        "Hi! I'm Ojasvi, and I'm a junior from the Chicago suburbs. I'm majoring in Data Science, Economics, and American Studies. I joined NIB Spring '22, and it has by far been the best decision I've made on campus! #NIBFam has been the absolute best community to be in, and the organization and people I've met through it have been so integral to my professional development.",
        "Through NIB, I've had the chance to work on projects from sustainability strategy for Nordstrom to revenue development for the Leukemia and Lymphoma Society. Professionally, I'm interning at Tesla as a Product Management Intern this semester, and will be working as an investment banking summer analyst this summer in NYC. In my free time, I love driving down Highway 1, exploring new food places in the Bay, and spending afternoons at Moe's Books in Berkeley."
    ],
    linkedin: 'https://www.linkedin.com/in/ojasvisaxena/',
    calendly: "https://calendly.com/ojasvi-saxena/nib-coffee-chats",
    image: require('../img/prof/ojasvi.png'),
    sillyImage: require('../img/funny/ojasvi.png'),
    isDM: false
},
{
    name: 'Pranit Jain', // Photos need to be added
    title: 'Senior Advisor',
    bio: [
        "Hey! I'm Pranit, a junior from India studying Data Science and Biomedical Engineering.",
        "NIB has been home away from home. After my stint as a self-proclaimed social entrepreneurship guru throughout high school, NIB has enabled me to continue scaling impact in areas such as sustainability & healthcare (a domain I'm especially excited about!) while affording me a lively and supportive environment that's second to none.",   
        "Outside of NIB, you'll probably find me trying to meditate, daydreaming about the beach, or losing at poker. Either way, I'd love nothing more than to chat with you – hit me up!"
    ],
    linkedin: 'http://linkedin.com/in/pranit-jain/',
    calendly: "https://calendly.com/pranitjain/coffee-chat",
    image: require('../img/prof/pranit.png'),
    sillyImage: require('../img/funny/pranit.png'),
    isDM: false
},

{
    name: 'Roland Chavez',
    title: 'Senior Advisor',
    bio: [ 
        "I joined NIB during my freshman fall and it has been one of the best decisions I have made. Through NIB, I have had the opportunity to help launch a nationwide Title-1 High School software and assisted one of the largest non-profits in improving the standard of living for millions of smallholder farmers.", 
        "Hello everyone, my name is Roland and I am currently a 3rd-year pursuing Business.", 
        "In my free time, l enjoy exploring the Bay, eating cheesecake, and doing anything aquatic (swimming, diving, water polo, scuba). I look forward to meeting you all during recruitment this semester!"
    ],
    linkedin: 'https://www.linkedin.com/in/roland-chavez/',
    calendly: 'https://calendly.com/rolandchavez/net-impact-coffee-chat-roland-chavez',
    image: require('../img/prof/lucky.png'),
    sillyImage: require('../img/funny/lucky.png'),
    isDM: false
},
// {
//     name: 'Ryan Jang',
//     title: 'VP Consulting',
//     bio: [
//         "Hi! My name is Ryan and I'm a senior from South Korea studying Environmental Economics and Policy. I joined Net Impact during the first semester of my junior year, and I'm so grateful for all the amazing experiences and people I've met so far. In the past I've worked on a project to make an impact in the space of tech accessibility as well as a project to understand global health product procurement in Africa.",
//         "I love all kinds of sports and really love meeting new and interesting people (everyone at Berkeley is). Come find me at an event or at tabling to learn more about NIB literally changed my life!"
//     ],
//     linkedin: 'https://www.linkedin.com/in/ryaneusukjang/',
//     image: require('../img/prof/ryan.png'),
//     sillyImage: require('../img/funny/ryan.png'),
//     isDM: false
// },
{
    name: 'Sejal Goel',
    title: 'Senior Advisor',
    bio: [
        "Hi there! I'm Sejal, a senior from New Delhi, India studying Economics and Business Administration. I joined Net Impact my freshman spring and I am VP Associate Development this semester. In the past, I've created market entry strategies for a women's sustainable period underwear company, worked with a career platform for BIPOC youth to enter STEM career pathways, and helped a national fashion retail store with their sustainability strategy.",
        "I spend my free time volunteering at a senior dog rescue and playing the piano. Feel free to sign up for a chat with me to talk about entrepreneurship, travel, animals, F1 races, music or anything else that interests you!"

    ],
    linkedin: 'https://www.linkedin.com/in/sejalgoel/',
    calendly: "https://calendly.com/sejal_goel/nib-coffee-chats",
    image: require('../img/prof/sejal.jpg'),
    sillyImage: require('../img/funny/sejal.png'),
    isDM: false
},
{
    name: 'Stuti Upadhyay', 
    title: 'Senior Advisor',
    bio: [
        "Hi Everyone! My name is Stuti, and I'm a senior studying Public Health and Business Administration with a minor in Data Science. I first joined NIB in my fall semester of Freshman year, and this is my eighth semester in the club! In the past, I've been on Exec, PMed, and done a bunch of other random positions, and I've worked and led projects related to refugees, electrification, education technology, and healthcare.",
        "This past summer, I worked at Strategy&/PwC as a Healthcare Consulting Intern. Previously, I've worked in various other pharma and healthcare startup companies. I'd love to talk about soccer/running, TV shows (Ted Lasso, Modern Family, Pysch), healthcare, women's empowerment, and having no cool hobbies. Looking forward to meeting you!"
    ],
    linkedin: 'https://www.linkedin.com/in/stuti-upadhyay/',
    calendly: "https://calendly.com/stuti-j-upadhyay",
    image: require('../img/prof/stuti.jpg'),
    sillyImage: require('../img/funny/stuti.png'),
    isDM: false
},
{
    name: 'Tsadiku Obolu', // Fa 23 Form needs to be submitted
    title: 'Senior Advisor',
    bio: [
        'Hey! I\'m Tsadi, a junior from Sacramento, CA studying EECS and Business. I joined Net Impact fall of my freshman year and I am currently a Senior Advisor this semester. In the past, I\'ve worked with Autodesk to build the next stage of sustainable design tools, an Edtech startup to develop their B2B business model, and a non-profit to build their learning management system.',
        'I\'m interested in financial literacy, education, technology, and product management. In the past I\'ve worked at Google as a STEP (SWE) intern and I am going to apart of Google\'s 2023 APM cohort. In my free time I love to play basketball, hike, and watch tv. Feel free to reach out to chat about anything!'
    ],
    linkedin: 'https://www.linkedin.com/in/tobolu',
    calendly: "https://calendly.com/tobolu/nib-coffee-chat",
    image: require('../img/prof/tsadi.jpg'),
    sillyImage: require('../img/funny/tsadi.png'),
    isDM: false
},
// {
//     name: 'Tobin Thannickal', 
//     title: 'Senior Advisor',
//     bio: [
//         "Hey! My name is Tobin, and I’m a senior studying Business Administration. I joined Net Impact my sophomore spring, and they weren’t lying when they said nibfam is the best fam.",
//         "This past summer, I worked as an Investment Banking Summer Analyst at Morgan Stanley, and previously at a software focused private equity firm called Diversis Capital. I’m originally from Los Angeles so always been a fan of the Lakers, hot chicken, and thrifting. Feel free to add some time on my Calendly."
//     ],
//     linkedin: 'https://www.linkedin.com/in/tobin-thannickal',
//     calendly: "https://calendly.com/tobinthannickal/nib-coffee-chats",
//     image: require('../img/prof/tobin.png'),
//     sillyImage: require('../img/funny/tobin.png'),
//     isDM: false
// }, 
        {
            name: 'Varun Nair',
            title:  'Senior Advisor',
            bio: [
                "Hey! I'm Varun, an international student who is a junior studying EECS. I joined Net Impact spring of my freshman year. In the past, I've worked on diversifying sustainable products for AutoDesk, developed ways to reduce systemic inequality for energy in California, created market entry strategies and distribution channels for a girls education non-profit and led my own project aiding the largest renewable energy developers in North America to create a financial model to ease their land leasing process.",
                "I'm interested in renewable energy, education, and technology. This past semester I worked as a SWE Intern at Coinbase within their security team to build sustainable development practices. In the past I've worked at several start-ups as an operations lead, software engineer, and UI/UX designer, and see myself exploring entrepreneurship or VC as a career path in the near future In my free time I love to read about start-ups and tech, travel, sing, watch Netflix and follow F1. Feel free to reach out to chat about anything! :)"
            ],
            linkedin: 'https://www.linkedin.com/in/varun-nair-berkeley/',
            calendly: "https://calendly.com/varun_nair/coffee-chat-with-varun-nib?month=2024-01",
            image: require('../img/prof/varun.png'),
            sillyImage: require('../img/funny/varun.png'),
            isDM: false
        },
        {
            name: 'Wenhan Xi', 
            title: 'Senior Advisor',
            bio: [
                "Hi there! I’m Han, and I’m a sophomore studying econ and data science. I joined Net Impact freshman fall and it's here in NIB that I've found a home. So far, I've had the opportunity to work on amazing projects from developing social enterprise partnership strategies for the aerospace industry to digital readiness with farmers and even on a corporate partnership strategy for an environmental advocacy group. I've been driven to grow in many dimensions in a supportive family who I've come to love deeply.",
                "Outside of NIB, I love judo, building houses and cooking up a storm in the kitchen (cleaning up after though is not my forté…). I’m super excited to meet you through recruitment this spring!"
            ],
            linkedin: 'https://www.linkedin.com/in/wenhan-xi-842440149/',
            calendly: "https://calendly.com/xiwenhan",
            image: require('../img/prof/han.jpg'),
            sillyImage: require('../img/funny/han.png'),
            isDM: false
        },
        

        
        
        
        
        
      
        
        
        
        
        
        
        
        
        
       
        
        
        
        
       
        ,
        
        
        

    ]
} 

export default memberInfo;